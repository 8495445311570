.Services {
    font-family: sans-serif;
    text-align: center;
}


* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

#root {
    height: 100vh;

}

.leaflet-container {
    height: 100vh;
}

.img-popup {
    width: 60px;
    height: 60px;
}

