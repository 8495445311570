*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  
  }
  
  

  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  
  .container{
    width: 95%;
    min-height: 100vh;
    position: relative;
    margin: 0 auto;
  }
  
  .container h1{
    color: #fff;
    text-align: center;
    padding: 15px;
    font-size: 40px;
    font-weight: bold;
  }
  
  .container .media-container{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 15px;
    margin: 10px auto;
    }
  
    .container .media-container .media{
      height: 220px;
      width: 100%;
      border: 5px solid #fff;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0);
      overflow: hidden;
      cursor: pointer;
  
    }
  
    .container .media-container .media :is(video, img){
      height: 100%;
      width: 100%;
      object-fit: cover;
      transition: all 0.3s linear;
    }
  
    .container .media-container .media:hover :is(video, img){
       transform:  scale(1.1);
    }
  
  
    .container .popup-media{
      position: fixed;
      top: 0;
      left: 0;
      z-index: 100;
      background: rgba(0, 0, 0, 0.8);
      width: 100%;
      height: 100%;
    }
  
    .container .popup-media :is(video, img) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      max-width: 95%;
      max-height: 95%;
      object-fit: contain;
      border: 3px solid #fff;
  
    }
    
    .container .popup-media span{
      position: absolute;
      top: 100px;
      right: 20px;
      font-size: 30px;
      font-weight: bolder;
      z-index: 100;
      cursor: pointer;
      color: #fff;
      user-select: none;
  
  
    }
  
    