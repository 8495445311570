* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.services,
.products,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.services {
  background-image: url('/src/images/img-2.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fcf4f4;
  font-size: 100px;
}

.products {
  background-image: url('/src/images/img-1.jpg');
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  background-image: url('/src/images/img-8.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.image-text {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-size: 34px;
  position: relative;
  top: 30px;
}

.zgo-text {
  text-align: down;
  position: relative;
  top: 80px;
  padding: 30px;
}

.slider {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  width: 1000px;
  height: 600px;
  border-radius: 10px;
}

.right-arrow {
  position:  absolute;
  top: 50%;
  right: 32px;
  font-size: 3rem;
  color: #000;
  z-index:  10;
  cursor: pointer;
  user-select: none;
}

.left-arrow {
  position:  absolute;
  top: 50%;
  left: 32px;
  font-size: 3rem;
  color: #000;
  z-index:  10;
  cursor: pointer;
  user-select: none;
}

.slide {
  opacity: 0;
  transition-duration: 1s ease;
}

.slide.active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);
}

.leaflet-container {
  height: 100vh;
}